
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import usePriceList from "@/composables/usePriceList";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IPriceListDto } from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
export default defineComponent({
  components: {},
  setup() {
    const { priceList, getPriceList } = usePriceList();
    const route = useRoute();
    const router = useRouter();
    const breadcrumbs = {
      title: "PriceList Details",
      breadcrumbs: ["PriceList", "Details"],
    };

    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;

    let modelValue = ref<IPriceListDto>({
      id: "",
      name: "",
      description: "",
      isDefault: false,
    });

    onMounted(async () => {
      // setCurrentPageBreadcrumbs("Products Details", ["Product", "Details"]);

      await getPriceList(route.params.id as string);

      modelValue.value = priceList.value;
    });

    return {
      router,
      modelValue,
      breadcrumbs,
      permissions,
    };
  },
});
